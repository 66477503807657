import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { i18nReducer, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import translations from '../locales';

import userReducer from './userSlice';
import userPermissionsReducer from './userPermissionsSlice';
import metadataReducer from './metadataSlice';

export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
    user: userReducer,
    userPermissions: userPermissionsReducer,
    metadata: metadataReducer,
  },
  middleware: getDefaultMiddleware(),
});

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('zh-tw'));
syncTranslationWithStore(store)
