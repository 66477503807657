import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Space,
  InputNumber,
  DatePicker, Badge, Radio,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function FamilyServiceEditModal (props) {
  const userData = useSelector(state => state.user.value)
  const {familyServiceId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [familyService, setFamilyService] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const familyServiceRes = await ToolRequest.request('GET', `/family-service/${familyServiceId}`)
        if(familyServiceRes.data.beginAt){
          familyServiceRes.data.beginAt = moment(familyServiceRes.data.beginAt)
        }
        if(familyServiceRes.data.endAt){
          familyServiceRes.data.endAt = moment(familyServiceRes.data.endAt)
        }
        if(familyServiceRes.data.terminatedAt){
          familyServiceRes.data.terminatedAt = moment(familyServiceRes.data.terminatedAt)
        }
        setFamilyService(familyServiceRes.data)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      const beginAt = values.beginAt ? moment(values.beginAt).startOf('days').toISOString() : null
      const endAt = values.endAt ? moment(values.endAt).endOf('days').toISOString() : null
      const data = {
        beginAt,
        endAt,
        terminatedAt: values.terminated === 'YES' ? moment().toISOString() : null,

      }
      console.dir(data)
      await ToolRequest.request('PUT', `/family-service/${familyServiceId}`, data)
      message.success(I18n.t('editSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('編輯家庭服務')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              {
                ...familyService,
                terminated:familyService.terminatedAt ? 'YES' : 'NO'
              }
            }
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('開始時間')}
              name="beginAt"
              required={true}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(!value){
                      return Promise.reject(new Error(I18n.t('請輸入開始時間')));
                    }
                    const endAt = getFieldValue('endAt')
                    if(moment(value).isAfter(endAt)){
                      return Promise.reject(new Error(I18n.t('開始時間不能大於結束時間')))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder={I18n.t('開始時間')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('結束時間')}
              name="endAt"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(value){
                      const beginAt = getFieldValue('beginAt')
                      if(moment(value).isBefore(beginAt)){
                        return Promise.reject(new Error(I18n.t('結束時間不能小於開始時間')))
                      }
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder={I18n.t('結束時間')}
              />
            </Form.Item>
            <Form.Item
              label="是否停用"
              name="terminated"
            >
              <Radio.Group>
                <Radio value="YES">是</Radio>
                <Radio value="NO">否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};