import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Tag,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import FamilyServiceAddModal from './FamilyServiceAddModal'
import FamilyServiceEditModal from './FamilyServiceEditModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolPermission from '../../tools/ToolPermission'

export default function FamilyServiceViewModal (props) {
  const userData = useSelector(state => state.user.value)
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [familyServiceAddModal, setFamilyServiceAddModal] = useState(null)
  const [familyServiceEditModal, setFamilyServiceEditModal] = useState(null)
  const {familyId,visible, onOk, onCancel} = props
  const [loading, setLoading] = useState(false)
  const [family, setFamily] = useState(null)
  const [services, setServices] = useState([])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'super-admin', 'get')
        return canEdit ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setFamilyServiceEditModal({
                      visible: true,
                      familyServiceId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/family-service/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯家庭服務')}
                  </Menu.Item>
                )}
                {ToolPermission.hasPermission(userPermissionsData, 'family-service', 'delete') &&(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'familyId',
      title: I18n.t('家庭'),
      render: (value, record) => {
        if(family.id === value){
          return family.familyNumber
        }
      },
    },
    {
      key: 'serviceId',
      title: I18n.t('服務'),
      render: (value, record) => {
        const service = services.find(item=>item.id === value)
        return service && service.name[userData.locale]
      },
    },
    {
      key: 'status',
      title: I18n.t('服務状态'),
      render: (value, record) => {
        return {
          ONGOING: <Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag>,
          WAITING: <Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag>,
          ENDED: <Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag>,
          TERMINATED: <Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag>,
        }[value];
        switch (value) {
          case 'ONGOING':{
            return '進行中'
          }
          case 'WAITING':{
            return '等待中'
          }
          case 'ENDED':{
            return '已結束'
          }
          case 'TERMINATED':{
            return '已中止'
          }
        }
      },
    },
    {
      template: 'datetime',
      key: 'beginAt',
      title: I18n.t('開始時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'endAt',
      title: I18n.t('结束時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const familyRes = await ToolRequest.request('GET', `/family/${familyId}`)
      setFamily(familyRes.data)
      const serviceRes = await ToolRequest.request('GET', `/service`)
      console.dir(serviceRes)
      setServices(serviceRes.data)
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const familyServiceRes = await ToolRequest.request('GET', `/family-service`, {
      familyId,
      ...body,
    })
    return familyServiceRes
  }

  return (
    <Modal
      title={I18n.t('家庭服務')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={I18n.t('家庭服務列表')}
            extra={[
              (ToolPermission.hasPermission(userPermissionsData, 'family-service', 'post') &&
                <Button
                  key={Math.random().toString()}
                  type="primary"
                  icon={<PlusOutlined/>}
                  onClick={() => setFamilyServiceAddModal({
                    familyId,
                    visible: true,
                    key: Math.random().toString(),
                  })}
                >
                  {I18n.t('add')}
                </Button>
              ),
            ]}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {familyServiceAddModal && <FamilyServiceAddModal
              key={familyServiceAddModal.key}
              visible={familyServiceAddModal.visible}
              familyId={familyServiceAddModal.familyId}
              onOk={() => {
                setFamilyServiceAddModal({
                  ...familyServiceAddModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setFamilyServiceAddModal({
                  ...familyServiceAddModal,
                  visible: false,
                })
              }}
            />}
            {familyServiceEditModal && <FamilyServiceEditModal
              key={familyServiceEditModal.key}
              visible={familyServiceEditModal.visible}
              familyServiceId={familyServiceEditModal.familyServiceId}
              onOk={() => {
                setFamilyServiceEditModal({
                  ...familyServiceEditModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setFamilyServiceEditModal({
                  ...familyServiceEditModal,
                  visible: false,
                })
              }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
