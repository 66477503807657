import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import Editor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'
import MarkdownIt from 'markdown-it'
import moment from 'moment';

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Space,
  InputNumber,
  DatePicker, Upload, Divider, Radio,
} from 'antd'
import {
  MinusCircleOutlined,
  EditOutlined,
  PlusOutlined,
  AppstoreAddOutlined,
  UploadOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import Import from '../../components/Import'

const {TextArea} = Input

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const mdParser = new MarkdownIt({
  html: true,
  breaks: true,
  linkify: true,
  typographer:true,
});

// 新的窗口打开链接
var defaultRender = mdParser.renderer.rules.link_open || function (tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};

mdParser.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  var aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']);
  } else {
    const attrs = tokens[idx].attrs
    if (attrs) {
      attrs[aIndex][1] = '_blank';
    }
  }
  return defaultRender(tokens, idx, options, env, self);
};

export default function SystemMessageEditModal (props) {
  const mdEditor = React.useRef(null);
  const userData = useSelector(state => state.user.value)
  const {systemMessageId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const metadata = useSelector(state => state.metadata.value);
  const [systemMessage, setSystemMessage] = useState(null)
  const [fileList, setFileList] = useState([]);
  const [textZhtw, setTextZhtw] = useState('');
  const [textZhcn, setTextZhcn] = useState('');
  const [textEn, setTextEn] = useState('');

  const handleEditorChangeZhtw = ({ html, text }) => {
    setTextZhtw(text);
  };
  const handleEditorChangeZhcn = ({ html, text }) => {
    setTextZhcn(text);
  };
  const handleEditorChangeEn = ({ html, text }) => {
    setTextEn(text);
  };

  const handleImageUploadZhtw = async (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);
    const importRes = await ToolRequest.request('POST', '/import/post/content-image', formData,{
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return importRes.data.url
  }
  const handleImageUploadZhcn = async (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);
    const importRes = await ToolRequest.request('POST', '/import/post/content-image', formData,{
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return importRes.data.url
  }
  const handleImageUploadEn = async (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);
    const importRes = await ToolRequest.request('POST', '/import/post/content-image', formData,{
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return importRes.data.url
  }

  const handleChange = (info) => {
    console.dir(info)
    if(info.file.error){
      return message.error(I18n.t('文件格式錯誤'))
    }
    setLoading(true)
    const fileList = info.fileList
    const data = fileList.map(file => ({
      status: file.status,
      uid: file.uid,
      url: file.response?file.response.data.url:file.url,
    }));
    setFileList(data)
    setLoading(false)
  };


  useEffect(() => {
    (async () => {
      try {
        const systemMessageRes = await ToolRequest.request('GET', `/system-message/${systemMessageId}`)
        const systemMessageNew = {...systemMessageRes.data};
        for (let locale of metadata.supportedLocales) {
          systemMessageNew[`title[${locale}]`] = systemMessageRes.data.title[locale];
        }
        for (let locale of metadata.supportedLocales) {
          systemMessageNew[`pushContent[${locale}]`] = systemMessageRes.data.pushContent[locale];
        }
        if(systemMessageNew.sentAt){
          systemMessageNew.sentAt = moment(systemMessageNew.sentAt).utcOffset(metadata.utcOffset)
        }
        if(systemMessageNew.expiredAt){
          systemMessageNew.expiredAt = moment(systemMessageNew.expiredAt).utcOffset(metadata.utcOffset)
        }
        setTextZhtw(systemMessageRes.data.content['zh-tw'])
        setTextZhcn(systemMessageRes.data.content['zh-cn'])
        setTextEn(systemMessageRes.data.content['en'])
        console.dir(systemMessageNew)
        setFileList([{url: systemMessageNew.thumbnailUrl}])
        setSystemMessage(systemMessageNew)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    try {
      let title = {}
      for (let locale of metadata.supportedLocales) {
        title[locale] = values[`title[${locale}]`]
      }
      let pushContent = {}
      for (let locale of metadata.supportedLocales) {
        pushContent[locale] = values[`pushContent[${locale}]`]
      }
      let content = {
        'zh-tw':textZhtw,
        'zh-cn':textZhcn,
        'en':textEn,
      }
      console.dir(fileList)
      let thumbnailUrl = fileList.length && fileList[0].response ? fileList[0].response.data.url : null
      const data = {
        thumbnailUrl,
        title,
        content,
        pushContent,
        expiredAt:values.expiredAt,
        sentAt:values.sentAt,
        type:values.type,
      }
      console.dir(data)
      await ToolRequest.request('PUT', `/system-message/${systemMessageId}`, data)
      message.success(I18n.t('editSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('編輯系統消息')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            onFinish={onFinish}
            initialValues={systemMessage}
          >
            <Form.Item
              label="類型"
              name="type"
            >
              <Radio.Group>
                <Radio value="SYSTEM">系統</Radio>
                <Radio value="USER">個人</Radio>
              </Radio.Group>
            </Form.Item>
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`title[${locale}]`}
                  label={`${I18n.t('標題')} (${locale})`}
                  name={`title[${locale}]`}
                  rules={[
                    {
                      required: true,
                      message: I18n.t('errorMessageRequiredInputAny', {
                        name: `${I18n.t('標題')} (${locale})`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={`${I18n.t('標題')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Divider />
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`pushContent[${locale}]`}
                  label={`${I18n.t('推送頭標題')} (${locale})`}
                  name={`pushContent[${locale}]`}
                  rules={[
                    {
                      required: true,
                      message: I18n.t('errorMessageRequiredInputAny', {
                        name: `${I18n.t('推送頭標題')} (${locale})`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={`${I18n.t('推送頭標題')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Form.Item
              label={I18n.t('發送日期')}
              name="sentAt"
            >
              <DatePicker showTime style={{width: '100%'}} placeholder={I18n.t('如不指定，將不會發送')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('過期日期')}
              name="expiredAt"
            >
              <DatePicker showTime style={{width: '100%'}} placeholder={I18n.t('如不指定，即是無限期')}/>
            </Form.Item>
            <Form.Item
              label={I18n.t('缩略图')}
            >
              <Import
                accept=".jpg, .jpeg, .png"
                action={`${metadata.cloudHost}import/system-message/thumbnail-image`}
                listType="picture"
                maxCount={1}
                fileList={fileList}
                setFileList={setFileList}
                buttonText="上傳圖片 (*.jpg;*.jpeg;*.png)"
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('內容 (繁體中文)')}
            >
              <Editor
                ref={mdEditor}
                value={textZhtw}
                style={{
                  height: "200px"
                }}
                onImageUpload={handleImageUploadZhtw}
                onChange={handleEditorChangeZhtw}
                renderHTML={text => mdParser.render(text)}
                imageAccept={`.jpg,.png`}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('內容 (簡體中文)')}
            >
              <Editor
                ref={mdEditor}
                value={textZhcn}
                style={{
                  height: "200px"
                }}
                onImageUpload={handleImageUploadZhcn}
                onChange={handleEditorChangeZhcn}
                renderHTML={text => mdParser.render(text)}
                imageAccept={`.jpg,.png`}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('內容 (英文)')}
            >
              <Editor
                ref={mdEditor}
                value={textEn}
                style={{
                  height: "200px"
                }}
                onImageUpload={handleImageUploadEn}
                onChange={handleEditorChangeEn}
                renderHTML={text => mdParser.render(text)}
                imageAccept={`.jpg,.png`}
              />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};