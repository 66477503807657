import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Form, Switch, Select, InputNumber,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import ServiceSettingEditModal from './ServiceSettingEditModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function ServiceSettingViewModal (props) {
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [serviceSettingEditModal, setServiceSettingEditModal] = useState(null)
  const [serviceSettingAddModal, setServiceSettingAddModal] = useState(null)
  const {serviceId,visible, onOk, onCancel} = props
  const [loading, setLoading] = useState(false)
  const [configSettings, setConfigSettings] = useState([])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setServiceSettingEditModal({
                      visible: true,
                      serviceSettingId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/service-setting/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'settingKey',
      title: I18n.t('鍵'),
      sorter:true,
      render: (value, record) => {
        const setting = configSettings.find(item=>item.key === value)
        return setting && setting.locales['zh-tw']
      },
    },
    {
      key: 'settingValue',
      title: I18n.t('值'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      sorter:true,
      title: I18n.t('createdAt'),
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const settingConfigRes = await ToolRequest.request('GET', '/service-setting/config')
      setConfigSettings(settingConfigRes.settings)
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const serviceSettingRes = await ToolRequest.request('GET', `/service-setting`, {
      serviceId,
      ...body,
    })
    console.dir(serviceSettingRes)
    return serviceSettingRes
  }

  return (
    <Modal
      title={I18n.t('')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={I18n.t('服務設置列表')}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {serviceSettingEditModal && <ServiceSettingEditModal
              key={serviceSettingEditModal.key}
              visible={serviceSettingEditModal.visible}
              serviceSettingId={serviceSettingEditModal.serviceSettingId}
              onOk={() => {
                setServiceSettingEditModal({
                  ...serviceSettingEditModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setServiceSettingEditModal({
                  ...serviceSettingEditModal,
                  visible: false,
                })
              }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
