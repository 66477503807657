import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import { Dropdown, Menu, Button, Modal, message, PageHeader,Avatar } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EyeOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import ServiceEditModal from './ServiceEditModal'
import ServiceSettingViewModal from './ServiceSettingViewModal'
import FamilyServiceViewModal from './FamilyServiceViewModal'
import ServiceAddModal from './ServiceAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolPermission from '../../tools/ToolPermission'

export default function FamilyViewScreen (props) {
  const metadata = useSelector(state => state.metadata.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const userData = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [serviceEditModal, setServiceEditModal] = useState(null)
  const [serviceAddModal, setFamilyAddModal] = useState(null)
  const [serviceSettingViewModal, setServiceSettingViewModal] = useState(null)
  const [familyServiceViewModal, setFamilyServiceViewModal] = useState(null)

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'super-admin', 'get')
        return canEdit ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setServiceEditModal({
                      visible: true,
                      serviceId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/service/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                  case 'service-setting': {
                    setServiceSettingViewModal({
                      visible: true,
                      serviceId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'family-service': {
                    setFamilyServiceViewModal({
                      visible: true,
                      serviceId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {ToolPermission.hasPermission(userPermissionsData, 'service', 'put') &&(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯服務')}
                  </Menu.Item>
                )}
                {ToolPermission.hasPermission(userPermissionsData, 'service', 'delete') &&(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除服務')}
                  </Menu.Item>
                )}
                {ToolPermission.hasPermission(userPermissionsData, 'service', 'put') &&(
                  <Menu.Item key="service-setting">
                    <EyeOutlined/>
                    {I18n.t('服務設置')}
                  </Menu.Item>
                )}
                <Menu.Item key="family-service">
                  <EyeOutlined/>
                  {I18n.t('家庭服務')}
                </Menu.Item>
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'name',
      title: I18n.t('名稱'),
      render: (value, record) => {
        return value && value[userData.locale]
      },
    },
    {
      key: 'iconUrl',
      title: I18n.t('圖標'),
      render: (value, record) => {
        return value && <Avatar
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 80,
            xxl: 100,
          }}
          src={value}
        />
      },
    },
    {
      key: 'duration',
      sorter:true,
      title: I18n.t('時長（天）')
    },
    {
      template: 'datetime',
      key: 'createdAt',
      sorter:true,
      title: I18n.t('createdAt'),
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const serviceRes = await ToolRequest.request('GET', '/service', {
      ...body,
    })
    return serviceRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('服務列表')}
      extra={[
        (ToolPermission.hasPermission(userPermissionsData, 'service', 'post') &&
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setFamilyAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
      {serviceEditModal && <ServiceEditModal
        key={serviceEditModal.key}
        visible={serviceEditModal.visible}
        serviceId={serviceEditModal.serviceId}
        onOk={() => {
          setServiceEditModal({
            ...serviceEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setServiceEditModal({
            ...serviceEditModal,
            visible: false,
          })
        }}
      />}
      {serviceAddModal && <ServiceAddModal
        key={serviceAddModal.key}
        visible={serviceAddModal.visible}
        onOk={() => {
          setFamilyAddModal({
            ...serviceAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setFamilyAddModal({
            ...serviceAddModal,
            visible: false,
          })
        }}
      />}
      {serviceSettingViewModal && <ServiceSettingViewModal
        key={serviceSettingViewModal.key}
        visible={serviceSettingViewModal.visible}
        serviceId={serviceSettingViewModal.serviceId}
        onOk={() => {
          setServiceSettingViewModal({
            ...serviceSettingViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setServiceSettingViewModal({
            ...serviceSettingViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
      />}
      {familyServiceViewModal && <FamilyServiceViewModal
        key={familyServiceViewModal.key}
        visible={familyServiceViewModal.visible}
        serviceId={familyServiceViewModal.serviceId}
        onOk={() => {
          setFamilyServiceViewModal({
            ...familyServiceViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setFamilyServiceViewModal({
            ...familyServiceViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};
