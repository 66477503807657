import React, { useState, useEffect } from 'react'
import { I18n } from 'react-redux-i18n'

import {
  Card,
  Row,
  Col,
  Collapse,
  Descriptions,
  List,
  Avatar,
  Statistic,
  Tooltip,
  Typography,
  Space,
  Button,
  Badge
} from 'antd'

import * as ToolRequest from '../../tools/ToolRequest'

import SpinFull from '../../components/SpinFull'

import '../../less/HomeScreen.less'

const {Title, Text, Paragraph} = Typography
const {Panel} = Collapse

export default function HomeScreen (props) {
  const [loaded, setLoaded] = useState(false)
  const [postCount, setPostCount] = useState(0)
  const [systemMessageCount, setSystemMessageCount] = useState(0)
  const [familyCount, setFamilyCount] = useState(0)
  const [serviceCount, setServiceCount] = useState(0)
  const [memberCount, setMemberCount] = useState(0)

  useEffect(() => {
    (async () => {
      try {
        const postRes = await ToolRequest.request('GET', `/post`, {limit: 0})
        setPostCount(postRes.count)
        const familyRes = await ToolRequest.request('GET', `/family`, {limit: 0})
        setFamilyCount(familyRes.count)
        const memberRes = await ToolRequest.request('GET', `/member`, {limit: 0})
        setMemberCount(memberRes.count)
        const systemMessageRes = await ToolRequest.request('GET', `/system-message`, {limit: 0})
        setSystemMessageCount(systemMessageRes.count)
        const serviceRes = await ToolRequest.request('GET', `/service`, {limit: 0})
        setServiceCount(serviceRes.count)
      } catch (err) {
        console.dir(err)
      }
      setLoaded(true)
    })()
  }, [])

  return loaded ? (
    <>
      <Card>
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title={I18n.t('家庭數量')}
              value={familyCount}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('公告數量')}
              value={postCount}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('系統消息數量')}
              value={systemMessageCount}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('服務數量')}
              value={serviceCount}
            />
          </Col>
          <Col span={6}>
            <Statistic
                title={I18n.t('家庭成員數量')}
                value={memberCount}
            />
          </Col>
        </Row>
      </Card>
    </>
  ) : <SpinFull/>
};
