import React, { useState, useEffect } from 'react';

import { Table } from 'antd';

import SuperTableColumn from './SuperTableColumn';

export default function SuperTableContent(props) {
  const [loading, setLoading] = useState(false);

  const {
    id,
    columns,
    queryParams = {},
    loadDataSource = () => {},
    dataSource = [],
    expandable = {},
    onChangeDataSource = () => {},
    selectable = false,
    selectedRowKeys = [],
    setSelectedRowKeys = () => {},
    onQueryParamsChange = () => {},
    onPaginationChange = () => {},
    pagination,
  } = props;

  useEffect(() => {
    _onChange(queryParams, pagination);
  }, []);

  const _onChange = (_queryParams, _pagination, filters, sorter) => {
    const {
      pageSize = 10,
      current = 1,
      total,
    } = _pagination;

    setLoading(true);

    onQueryParamsChange(_queryParams)

    onPaginationChange({
      ...pagination,
      ..._pagination,
      current,
      total,
      pageSize,
    })

    if (loadDataSource) {
      loadDataSource({
        ..._queryParams,
        offset: pageSize * (current - 1),
        limit: pageSize,
        sort:sorter ? sorter.field : 'created_at',
        order:sorter && sorter.order ? sorter.order : 'desc',
      })
      .then((res) => {
        const {data, count} = res;

        setLoading(false);
        onChangeDataSource(data.map((record, index) => ({
            ...record,
            key: record.id !== undefined ? record.id : index
          })));

        onPaginationChange({
          ...pagination,
          ..._pagination,
          total: count,
        })
      })
    } else {
      setLoading(false);
    }
  };

  // calculate width
  const scroll = {};

  let widthTotal = 0;
  columns.forEach(column => widthTotal += parseInt(column.width) || 240);
  scroll.x = widthTotal

  return (
    <Table
      id={id}
      className="super-table"
      size="small"
      loading={loading}
      expandable={expandable}
      columns={columns.map(column => SuperTableColumn(column))}
      dataSource={dataSource}
      pagination={pagination}
      rowSelection={selectable ? {
        type: selectable,
        selectedRowKeys: selectedRowKeys,
        onSelectAll:(selected, selectedRows, changeRows)=>{
          let keys = []
          if(selected){
            for (let changeRow of changeRows){
              if(selectedRowKeys.indexOf(changeRow.key) < 0){
                keys.push(changeRow.key)
              }
            }
            setSelectedRowKeys(keys.concat(selectedRowKeys))
          }else {
            const keyArr = keys.concat(selectedRowKeys)
            for (let changeRow of changeRows){
              const index = keyArr.indexOf(changeRow.key)
              if(index >= 0){
                keyArr.splice(index,1)
              }
            }
            setSelectedRowKeys(keyArr)
          }
        },
        onSelect: (record, selected, selectedRows, nativeEvent)=>{
          let keys = []
          if(selected){
            if(selectedRowKeys.indexOf(record.key) < 0){
              keys.push(record.key)
            }
            setSelectedRowKeys(keys.concat(selectedRowKeys))
          }else {
            const keyArr = keys.concat(selectedRowKeys)
            const index = keyArr.indexOf(record.key)
            if(index >= 0){
              keyArr.splice(index,1)
            }
            setSelectedRowKeys(keyArr)
          }
        }
      } : null}
      onChange={(_pagination, filters, sorter) => _onChange(queryParams, _pagination, filters, sorter)}
      // scroll = {{
      //   ...scroll,
      // }}
    />
  );
};
