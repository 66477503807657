import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Space,
  InputNumber,
  DatePicker, Badge,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function FamilyEditModal (props) {
  const userData = useSelector(state => state.user.value)
  const {familyId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [regionOptions, setRegionOptions] = useState([])
  const [family, setFamily] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const regionRes = await ToolRequest.request('GET', `/region`)
        const regionOptions = regionRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setRegionOptions(regionOptions)
        const familyRes = await ToolRequest.request('GET', `/family/${familyId}`)
        console.dir(familyRes)
        if(familyRes.data.smsSentAt){
          familyRes.data.smsSentAt = moment(familyRes.data.smsSentAt)
        }
        setFamily(familyRes.data)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      const data = {
        isEnabled: values.isEnabled,
        remark: values.remark,
        economicStatus: values.economicStatus,
        monthlyIncome: values.monthlyIncome,
        address: values.address,
        regionId: values.regionId,
        smsSentAt: values.smsSentAt,
        locale: values.locale,
        applicantPhone: values.applicantPhone,
      }
      await ToolRequest.request('PUT', `/family/${familyId}`, data)
      message.success(I18n.t('editSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          case 'INVALID_PARAMS': {
            return message.error(I18n.t('無效的參數'))
          }
          default:
            return message.error(err.response.data)
        }
      }
      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('編輯家庭')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={family}
            onFinish={onFinish}
          >
            <Form.Item
              label="啟用"
              name="isEnabled"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked={true}/>
            </Form.Item>
            <Form.Item
              label={I18n.t('地區')}
              name="regionId"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('地區')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {regionOptions}
              </Select>
            </Form.Item>
            <Form.Item
                label={I18n.t('聯繫電話')}
                name="applicantPhone"
                rules={[
                  {
                    required: true
                  },
                ]}
            >
              <Input
                  placeholder={I18n.t('聯繫電話')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('經濟狀況')}
              name="economicStatus"
            >
              <Select placeholder={I18n.t('經濟狀況')}>
                <Option value="有收入">
                  {I18n.t('有收入')}
                </Option>
                <Option value="領取綜援金">
                  {I18n.t('領取綜援金')}
                </Option>
                <Option value="其它">
                  {I18n.t('其它')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('每月收入')}
              name="monthlyIncome"
            >
              <InputNumber
                placeholder={I18n.t('每月收入')}
                min={0}
                step={1} style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('短訊發送時間')}
              name="smsSentAt"
            >
              <DatePicker
                showTime
                disabled={family.smsSentState}
                placeholder={I18n.t('短訊發送時間')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('短訊發送狀態')}
              name="smsSentAt"
            >
              <Badge status={family.smsSentState ? 'success' : 'error'} text={family.smsSentState ? '已發送' : '未發送'}/>
            </Form.Item>
            <Form.Item
              label={I18n.t('語言')}
              name="locale"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select placeholder={I18n.t('語言')}>
                <Option value="zh-tw">
                  {I18n.t('繁體中文')}
                </Option>
                <Option value="zh-cn">
                  {I18n.t('簡體中文')}
                </Option>
                <Option value="en">
                  {I18n.t('英文')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('地址')}
              name="address"
            >
            <TextArea
              placeholder={I18n.t('地址')}
            />
            </Form.Item>
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};