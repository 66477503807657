import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Badge, Switch, Select, InputNumber,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import MemberAddModal from './MemberAddModal'
import MemberEditModal from './MemberEditModal'
import MemberTransferModal from './MemberTransferModal'
import MemberServiceViewModal from './MemberServiceViewModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function MemberViewModal (props) {
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [memberEditModal, setMemberEditModal] = useState(null)
  const [memberTransferModal, setMemberTransferModal] = useState(null)
  const [memberAddModal, setMemberAddModal] = useState(null)
  const [memberServiceViewModal, setMemberServiceViewModal] = useState(null)
  const {familyId,visible, onOk, onCancel} = props
  const [loading, setLoading] = useState(false)
  const [family, setFamily] = useState(null)

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
              menu={{
                items:[
                  {
                    key: 'member-service',
                    label: '成員服務'
                  },
                  {
                    key: 'transfer',
                    label: '轉移成員'
                  },
                  {
                    key: 'edit',
                    label: '編輯'
                  },
                  {
                    key: 'remove',
                    label: '刪除'
                  },
                ],
                onClick: ({key}) => {
                  switch (key) {
                    case 'edit': {
                      setMemberEditModal({
                        visible: true,
                        memberId: record.id,
                        key: Math.random().toString(),
                      })
                      break
                    }
                    case 'transfer': {
                      setMemberTransferModal({
                        visible: true,
                        memberId: record.id,
                        key: Math.random().toString(),
                      })
                      break
                    }
                    case 'remove': {
                      Modal.confirm({
                        title: I18n.t('removeConfirmMessage'),
                        okType: 'danger',
                        onOk: () => {
                          (async () => {
                            try {
                              await ToolRequest.request('DELETE', `/member/${record.id}`)
                              message.success(I18n.t('removeSuccessMessage'))
                              setTableKey(Math.random().toString())
                            } catch (err) {
                              if (err?.response?.data) {
                                message.error(err.response.data)
                              }else {
                                message.error(I18n.t('removeErrorMessage'))
                              }
                            }
                          })()
                        },
                      })
                      break
                    }
                    case 'member-service': {
                      setMemberServiceViewModal({
                        visible: true,
                        memberId: record.id,
                        key: Math.random().toString(),
                      })
                      break
                    }
                  }
                }
              }}
          >
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      template: 'isEnabled',
      key: 'id',
      title: I18n.t('申请人'),
      render: (value, record) => {
        if(family.mainMemberId === value){
          return <Badge status="success"/>
        }else {
          return <Badge status="default"/>
        }
      },
    },
    {
      key: 'gender',
      title: I18n.t('性別'),
      sorter:true,
      render: (value, record) => {
        if(value){
          return value === 'FEMALE' ? '女' : '男'
        }
      },
    },
    {
      key: 'chineseFullName',
      title: I18n.t('中文姓名'),
      sorter:true,
    },
    {
      key: 'firstNameEn',
      title: I18n.t('英文名字'),
      sorter:true,
    },
    {
      key: 'lastNameEn',
      title: I18n.t('英文姓氏'),
      sorter:true,
    },
    {
      key: 'idNumber',
      title: I18n.t('身份證號'),
      sorter:true,
    },
    {
      key: 'phone',
      title: I18n.t('電話'),
      sorter:true,
    },
    {
      key: 'birthDate',
      title: I18n.t('生日'),
      sorter:true,
    },
    {
      key: 'relation',
      title: I18n.t('同申請人關係'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const familyRes = await ToolRequest.request('GET', `/family/${familyId}`)
      setFamily(familyRes.data)
      setLoaded(true)
    })()
  }, [tableKey])

  const loadDataSource = async (body) => {
    const memberRes = await ToolRequest.request('GET', `/member`, {
      familyId,
      ...body,
    })
    return memberRes
  }

  return (
    <Modal
      title={I18n.t('')}
      open={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={I18n.t('家庭成員列表')}
            extra={[
              (
                <Button
                  key={Math.random().toString()}
                  type="primary"
                  icon={<PlusOutlined/>}
                  onClick={() => setMemberAddModal({
                    familyId,
                    visible: true,
                    key: Math.random().toString(),
                  })}
                >
                  {I18n.t('add')}
                </Button>
              ),
            ]}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {memberAddModal && <MemberAddModal
              key={memberAddModal.key}
              visible={memberAddModal.visible}
              familyId={memberAddModal.familyId}
              onOk={() => {
                setMemberAddModal({
                  ...memberAddModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setMemberAddModal({
                  ...memberAddModal,
                  visible: false,
                })
              }}
            />}
            {memberEditModal && <MemberEditModal
              key={memberEditModal.key}
              visible={memberEditModal.visible}
              memberId={memberEditModal.memberId}
              onOk={() => {
                setMemberEditModal({
                  ...memberEditModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setMemberEditModal({
                  ...memberEditModal,
                  visible: false,
                })
              }}
            />}
            {memberTransferModal && <MemberTransferModal
                key={memberTransferModal.key}
                visible={memberTransferModal.visible}
                memberId={memberTransferModal.memberId}
                onOk={() => {
                  setMemberTransferModal({
                    ...memberTransferModal,
                    visible: false,
                  })
                  setTableKey(Math.random().toString())
                }}
                onCancel={() => {
                  setMemberTransferModal({
                    ...memberTransferModal,
                    visible: false,
                  })
                }}
            />}
            {memberServiceViewModal && <MemberServiceViewModal
                key={memberServiceViewModal.key}
                visible={memberServiceViewModal.visible}
                memberId={memberServiceViewModal.memberId}
                onOk={() => {
                  setMemberServiceViewModal({
                    ...memberServiceViewModal,
                    visible: false,
                  })
                  setTableKey(Math.random().toString())
                }}
                onCancel={() => {
                  setMemberServiceViewModal({
                    ...memberServiceViewModal,
                    visible: false,
                  })
                }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
