import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import { Dropdown, Menu, Button, Modal, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import PostEditModal from './PostEditModal'
import PostAddModal from './PostAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolPermission from '../../tools/ToolPermission'

export default function PostViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [members, setMembers] = useState([])
  const [postEditModal, setPostEditModal] = useState(null)
  const [postAddModal, setPostAddModal] = useState(null)
  const [regions, setRegions] = useState([])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'super-admin', 'get')
        return canEdit ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setPostEditModal({
                      visible: true,
                      postId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/post/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {ToolPermission.hasPermission(userPermissionsData, 'post', 'put') &&(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯公告')}
                  </Menu.Item>
                )}
                {ToolPermission.hasPermission(userPermissionsData, 'post', 'delete') &&(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除公告')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'title',
      title: I18n.t('標題'),
      render: (value, record) => {
        return value && value[userData.locale]
      },
    },
    {
      template: 'isEnabled',
      key: 'isVisible',
      title: I18n.t('是否可見'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'sentAt',
      title: I18n.t('發送時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const postRes = await ToolRequest.request('GET', '/post', {
      ...body,
    })
    return postRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('公告列表')}
      extra={[
        (ToolPermission.hasPermission(userPermissionsData, 'post', 'post') &&
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setPostAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
      {postEditModal && <PostEditModal
        key={postEditModal.key}
        visible={postEditModal.visible}
        postId={postEditModal.postId}
        onOk={() => {
          setPostEditModal({
            ...postEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setPostEditModal({
            ...postEditModal,
            visible: false,
          })
        }}
      />}
      {postAddModal && <PostAddModal
        key={postAddModal.key}
        visible={postAddModal.visible}
        onOk={() => {
          setPostAddModal({
            ...postAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setPostAddModal({
            ...postAddModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};
