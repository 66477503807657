import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Divider,
  InputNumber,
  DatePicker,
} from 'antd'
import {
  MinusCircleOutlined,
  EditOutlined,
  PlusOutlined,
  AppstoreImportOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import Import from '../../components/Import'
import moment from 'moment'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function FamilyImportModal (props) {
  const metadata = useSelector(state => state.metadata.value)
  const userData = useSelector(state => state.user.value)
  const {visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [services, setServices] = useState(null)
  const [serviceOptions, setServiceOptions] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      try {
        const serviceRes = await ToolRequest.request('GET', `/service`)
        setServices(serviceRes.data)
        const options = serviceRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setServiceOptions(options)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    console.dir(fileList)
    console.dir(values)
    try {
      if(!fileList.length){
        await Promise.reject(new Error('請上傳文件'))
      }
      if(fileList[0].response.errorMessage){
        await Promise.reject(new Error(fileList[0].response.errorMessage))
      }
      let flag = true
      if (!values.smsSentAt) {
        flag = await new Promise((resolve, reject) => {
          Modal.confirm({
            title: I18n.t('短訊發送時間尚未選擇，確定要留空嗎？留空將不會發送短訊'),
            okType: 'danger',
            onOk: () => {
              resolve(true)
            },
            onCancel: () => {
              resolve(false)
            },
          })
        })
      }
      if (flag) {
        const data = {
          serviceId: values.serviceId,
          smsSentAt: values.smsSentAt,
          data:fileList[0].response.data,
          beginAt: values.beginAt,
          endAt: values.endAt,
          memberServiceOption: values.memberServiceOption,
        }
        console.dir(data)
        setLoading(true)
        const familyRes = await ToolRequest.request('POST', `/import/family`, data)
        message.success(familyRes.message)
        onOk()
        setLoading(false)
      }
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }
      const errorMessage = err.message || message.error(I18n.t('errorMessageUnknownError'))
      message.error(errorMessage)
    }
  }

  return (
    <Modal
      title={I18n.t('批量導入家庭')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            form={form}
            initialValues={{
              beginAt:moment()
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('服務')}
              name="serviceId"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('服務')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => {
                  if(value){
                    const serviceData = services.find(item=>item.id === value)
                    if(serviceData && serviceData.duration){
                      console.dir(serviceData.duration)
                      const endAt = moment().add(serviceData.duration,'days')
                      form.setFieldsValue({endAt})
                    }else {
                      form.setFieldsValue({endAt:null})
                    }
                  }
                }}
              >
                {serviceOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('服務開始時間')}
              name="beginAt"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <DatePicker
                placeholder={I18n.t('服務開始時間')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('服務結束時間')}
              name="endAt"
            >
              <DatePicker
                placeholder={I18n.t('服務結束時間')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('短訊發送時間')}
              name="smsSentAt"
            >
              <DatePicker
                showTime
                placeholder={I18n.t('短訊發送時間')}
              />
            </Form.Item>
            <Form.Item
                label={I18n.t('成員服務')}
                name="memberServiceOption"
                validateStatus="error"
                help="是否在子服務中啟用該家庭的所有成員，會影響某些子服務的配額，請謹慎開啟。如之前曾加入過此服務，可選擇 ‘保持不變’，使用舊設置。要單獨設置成員子服務，可前往 家庭列表->家庭成員->成員服務列表設置"
                rules={[
                  {
                    required: true
                  },
                ]}
            >
              <Select placeholder={I18n.t('成員服務')}>
                <Option value="keep">
                  {I18n.t('保持不變')}
                </Option>
                <Option value="true">
                  {I18n.t('開啟')}
                </Option>
                <Option value="false">
                  {I18n.t('關閉')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('選擇文件')}
              name="file"
            >
              <Import
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                action={`${metadata.cloudHost}import/check-family`}
                listType="text"
                maxCount={1}
                fileList={fileList}
                setFileList={setFileList}
                buttonText="上傳文件 (*.xlsx;*.xls)"
              />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};