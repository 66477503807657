import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Layout } from 'antd';
import { HomeOutlined, ClusterOutlined, GiftOutlined, NotificationOutlined,FileOutlined,DollarCircleOutlined,UnorderedListOutlined,
  UserOutlined, ShopOutlined, MoneyCollectOutlined, SettingOutlined, GlobalOutlined } from '@ant-design/icons';

import * as ToolDevice from '../../tools/ToolDevice';

import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import * as ToolPermission from '../../tools/ToolPermission'

import '../../less/App.less';

export default function AppScreen(props) {
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const [menuCollapsed, setMenuCollapsed] = useState(ToolDevice.isMobile());

  const menu = [
    {
      key: '/',
      title: I18n.t('主頁'),
      match: '^\/$',
    },
    {
      key: '/post',
      title: I18n.t('公告'),
      match: '^\/post',
    },
    {
      key: '/family',
      title: I18n.t('family'),
      match: '^\/family',
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'super-admin', 'get') ? [
      {
        key: '/system-message',
        title: I18n.t('系統消息'),
        match: '^\/system-message',
      },
    ] : []),
    {
      key: '/service',
      title: I18n.t('服務'),
      match: '^\/service',
    },
    {
      key: '/user',
      title: I18n.t('用戶'),
      match: '^\/user',
    },
    {
      key: '/region',
      title: I18n.t('地區'),
      match: '^\/region',
    },
  ];

  return (
    <Layout id="mainLayout">
      <AppMenu
        collapsed={menuCollapsed}
        setCollapsed={setMenuCollapsed}
        menu={menu}
      />
      <Layout>
        <AppHeader
          collapsed={menuCollapsed}
          setCollapsed={setMenuCollapsed}
        />
        <AppContent />
      </Layout>
    </Layout>
  );
}
