import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import { Modal, Form, Input, Button, Spin, Select, message, Switch,Space,InputNumber } from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import Import from '../../components/Import'

const {TextArea} = Input

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

export default function ServiceEditModal (props) {
  const {serviceId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const metadata = useSelector(state => state.metadata.value);
  const [service, setService] = useState(null)
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const serviceRes = await ToolRequest.request('GET', `/service/${serviceId}`)
        const serviceNew = {...serviceRes.data};
        for (let locale of metadata.supportedLocales) {
          serviceNew[`name[${locale}]`] = serviceRes.data.name[locale];
          serviceNew[`description[${locale}]`] = serviceRes.data.description[locale];
        }
        setService(serviceNew)
        if(serviceRes.data.iconUrl){
          setFileList([{url: serviceRes.data.iconUrl}])
        }
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    try {
      let name = {};
      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`];
      }
      let description = {};
      for (let locale of metadata.supportedLocales) {
        description[locale] = values[`description[${locale}]`];
      }
      console.dir(fileList)
      let iconUrl = fileList.length && fileList[0].response ? fileList[0].response.data.url : null
      if(fileList.length && fileList[0].url){
        iconUrl = fileList[0].url
      }
      console.dir(iconUrl)
      const data = {
        name,
        description,
        duration:values.duration,
        code:values.code,
        remark:values.remark,
        entryUrl:values.entryUrl,
        isSyncEnabled:values.isSyncEnabled,
        iconUrl,
      }
      console.dir(data)
      await ToolRequest.request('PUT', `/service/${serviceId}`, data)
      message.success(I18n.t('editSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          case 'DUPLICATE_SERVICE_CODE': {
            return message.error(I18n.t('服務代號重覆，請重新設置'))
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('編輯服務')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={service}
            onFinish={onFinish}
          >
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`name[${locale}]`}
                  label={`${I18n.t('名稱')} (${locale})`}
                  name={`name[${locale}]`}
                  rules={[
                    {
                      required: true,
                      message: I18n.t('errorMessageRequiredInputAny', {
                        name: `${I18n.t('名稱')} (${locale})`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={`${I18n.t('名稱')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Form.Item
              label={I18n.t('缩略图')}
              name="thumbnailUrl"
            >
              <Import
                accept=".jpg, .jpeg, .png"
                action={`${metadata.cloudHost}import/service/icon-image`}
                listType="picture"
                maxCount={1}
                fileList={fileList}
                setFileList={setFileList}
                buttonText="上傳圖片 (*.jpg;*.jpeg;*.png)"
              />
            </Form.Item>
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`description[${locale}]`}
                  label={`${I18n.t('描述')} (${locale})`}
                  name={`description[${locale}]`}
                >
                  <Input
                    placeholder={`${I18n.t('描述')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Form.Item
              label={I18n.t('時長（天）')}
              name="duration"
            >
              <InputNumber
                placeholder={I18n.t('時長（天）')}
                min={1}
                step={1} style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('代號')}
              name="code"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Input
                placeholder={I18n.t('代號')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('訪問地址')}
              name="entryUrl"
            >
              <Input
                placeholder={I18n.t('訪問地址')}
              />
            </Form.Item>
            <Form.Item
                label="啟用同步"
                name="isSyncEnabled"
                valuePropName="checked"
            >
              <Switch defaultChecked={true}/>
            </Form.Item>
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};