import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import { Dropdown, Menu, Button, Modal, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import SystemMessageEditModal from './SystemMessageEditModal'
import SystemMessageAddModal from './SystemMessageAddModal'
import UserSystemMessageViewModal from './UserSystemMessageViewModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolPermission from '../../tools/ToolPermission'

export default function SystemMessageViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [systemMessageEditModal, setSystemMessageEditModal] = useState(null)
  const [systemMessageAddModal, setSystemMessageAddModal] = useState(null)
  const [userSystemMessageViewModal, setUserSystemMessageViewModal] = useState(null)

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setSystemMessageEditModal({
                      visible: true,
                      systemMessageId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/system-message/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                  case 'user-system-message': {
                    setUserSystemMessageViewModal({
                      visible: true,
                      systemMessageId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {ToolPermission.hasPermission(userPermissionsData, 'system-message', 'put') &&(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯系統消息')}
                  </Menu.Item>
                )}
                {ToolPermission.hasPermission(userPermissionsData, 'system-message', 'delete') &&(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除系統消息')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="user-system-message">
                    <EyeOutlined/>
                    {I18n.t('用戶系統消息')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'title',
      title: I18n.t('標題'),
      render: (value, record) => {
        return value && value[userData.locale]
      },
    },
    {
      template: 'datetime',
      key: 'sentAt',
      title: I18n.t('發送時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const systemMessageRes = await ToolRequest.request('GET', '/system-message', {
      ...body,
    })
    return systemMessageRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('系統消息列表')}
      extra={[
        (ToolPermission.hasPermission(userPermissionsData, 'system-message', 'post') &&
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setSystemMessageAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
      {systemMessageEditModal && <SystemMessageEditModal
        key={systemMessageEditModal.key}
        visible={systemMessageEditModal.visible}
        systemMessageId={systemMessageEditModal.systemMessageId}
        onOk={() => {
          setSystemMessageEditModal({
            ...systemMessageEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setSystemMessageEditModal({
            ...systemMessageEditModal,
            visible: false,
          })
        }}
      />}
      {systemMessageAddModal && <SystemMessageAddModal
        key={systemMessageAddModal.key}
        visible={systemMessageAddModal.visible}
        onOk={() => {
          setSystemMessageAddModal({
            ...systemMessageAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setSystemMessageAddModal({
            ...systemMessageAddModal,
            visible: false,
          })
        }}
      />}
      {userSystemMessageViewModal && <UserSystemMessageViewModal
        key={userSystemMessageViewModal.key}
        visible={userSystemMessageViewModal.visible}
        systemMessageId={userSystemMessageViewModal.systemMessageId}
        onOk={() => {
          setUserSystemMessageViewModal({
            ...userSystemMessageViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setUserSystemMessageViewModal({
            ...userSystemMessageViewModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};
