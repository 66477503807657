import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment';

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  InputNumber,
  DatePicker, Radio, Divider,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function MemberTransferModal (props) {
  const metadata = useSelector(state => state.metadata.value);
  const {memberId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [familyOptions, setFamilyOptions] = useState([])
  const [families, setFamilies] = useState([])
  const [member, setMember] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        setLoaded(false)
        const memberRes = await ToolRequest.request('GET', `/member/${memberId}`)
        const familyRes = await ToolRequest.request('GET', `/family`,{limit:5000})
        setMember({
          ...memberRes.data
        })
        setFamilies(familyRes.data)
        const faOptions = familyRes.data.map(family=>{
          return {
            value:family.id,
            label: `${family.familyNumber} (${family.applicantPhone})`
          }
        })
        setFamilyOptions(faOptions)
        setLoaded(true)
      } catch (e) {
        setLoaded(false)
        message.error(e)
      }
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      const data = {
        familyId:values.familyId,
        memberId
      }
      console.dir(data)
      await ToolRequest.request('POST', `/member-family-transfer-record`, data)
      const family = families.find(item=>item.id === values.familyId)
      if(family){
        message.success(I18n.t(`已成功轉移到 ${family.familyNumber} 家庭`))
      }
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(err.response.data)
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={`轉移家庭成員 (${member && member.firstNameEn || ''} ${member && member.lastNameEn || ''} ${member && member.chineseFullName || ''})`}
      open={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={{}}
            onFinish={onFinish}
          >
            <Form.Item
                label={I18n.t('選擇家庭')}
                name="familyId"
                rules={[
                  {
                    required: true
                  },
                ]}
            >
              <Select
                  showSearch
                  placeholder={I18n.t('家庭')}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={familyOptions}
              >
              </Select>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};