import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Checkbox,
  Typography,
  DatePicker, Badge,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select
const { Text} = Typography

export default function FamilyBatchEditModal (props) {
  const userData = useSelector(state => state.user.value)
  const {familyIds,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [regionOptions, setRegionOptions] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const regionRes = await ToolRequest.request('GET', `/region`)
        const regionOptions = regionRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setRegionOptions(regionOptions)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    console.dir(values)
    try {
      let data = {}
      for (let key in values){
        const value = values[key]
        if(value && value.hasOwnProperty('checkbox') && value.checkbox){
          data[key] = value.value
        }
      }
      console.dir(data)
      if(Object.keys(data).length <=0){
        await Promise.reject(new Error('請至少選擇一個修改項'))
      }
      Modal.confirm({
        title: `已選擇 ${Object.keys(data).length} 個家庭，保存請按確定，返回選擇請按取消`,
        okType: 'danger',
        onOk: () => {
          (async () => {
            try {
              setLoading(true)
              for (let familyId of familyIds){
                await ToolRequest.request('PUT', `/family/${familyId}`, data)
              }
              message.success(I18n.t('editSuccessMessage'))
              onOk()
              setLoading(false)
            } catch (err) {
              console.dir(err)
              setLoading(false)
              if (err?.response?.data) {
                console.dir(err.response.data)
                switch (err.response.data) {
                  default:
                    return message.error(I18n.t('errorMessageUnknownError'))
                }
              }else {
                if(err && err.message){
                  message.error(err.message)
                }else {
                  message.error(I18n.t('errorMessageUnknownError'))
                }
              }
            }
          })()
        },
      })
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }else {
        message.error(err && err.message)
      }
    }
  }

  return (
    <Modal
      title={I18n.t('批量編輯家庭')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              {
                isEnabled:{
                  value:true
              }
            }
          }
            onFinish={onFinish}
          >
            <Text type="warning">注意：在提交前請務必先在需要修改的選項後面打√</Text>
            <Form.Item label="啟用">
              <Input.Group compact>
                <Form.Item
                    noStyle={false}
                    valuePropName="checked"
                    name={['isEnabled', 'value']}
                >
                  <Switch/>
                </Form.Item>
                <Form.Item
                    noStyle={false}
                    valuePropName="checked"
                    name={['isEnabled', 'checkbox']}
                    style={{marginLeft:"10px"}}
                >
                  <Checkbox>修改此項</Checkbox>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="地區">
              <Input.Group compact>
                <Form.Item
                    noStyle={false}
                    name={['regionId', 'value']}
                >
                  <Select
                      style={{
                        width: '200px',
                      }}
                      showSearch
                      placeholder={I18n.t('選擇地區')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                  >
                    {regionOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                    noStyle={false}
                    valuePropName="checked"
                    name={['regionId', 'checkbox']}
                    style={{marginLeft:"10px"}}
                >
                  <Checkbox>修改此項</Checkbox>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="經濟狀況">
              <Input.Group compact>
                <Form.Item
                    style={{
                      width: '200px',
                    }}
                    name={['economicStatus', 'value']}
                >
                  <Select placeholder={I18n.t('經濟狀況')}>
                    <Option value="有收入">
                      {I18n.t('有收入')}
                    </Option>
                    <Option value="領取綜援金">
                      {I18n.t('領取綜援金')}
                    </Option>
                    <Option value="其它">
                      {I18n.t('其它')}
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                    noStyle={false}
                    valuePropName="checked"
                    name={['economicStatus', 'checkbox']}
                    style={{marginLeft:"10px"}}
                >
                  <Checkbox>修改此項</Checkbox>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="短訊發送時間">
              <Input.Group compact>
                <Form.Item
                    name={['smsSentAt', 'value']}
                >
                  <DatePicker
                      showTime
                      placeholder={I18n.t('短訊發送時間')}
                  />
                </Form.Item>
                <Form.Item
                    noStyle={false}
                    valuePropName="checked"
                    name={['smsSentAt', 'checkbox']}
                    style={{marginLeft:"10px"}}
                >
                  <Checkbox>修改此項</Checkbox>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="備註">
              <Input.Group compact>
                <Form.Item
                    name={['remark', 'value']}
                >
                  <TextArea
                      placeholder={I18n.t('備註')}
                  />
                </Form.Item>
                <Form.Item
                    noStyle={false}
                    valuePropName="checked"
                    name={['remark', 'checkbox']}
                    style={{marginLeft:"10px"}}
                >
                  <Checkbox>修改此項</Checkbox>
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};