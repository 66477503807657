import React, { useState } from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';

import { Layout } from 'antd';

import HomeScreen from '../Home/HomeScreen';
import FamilyViewScreen from '../Family/FamilyViewScreen';
import RegionViewScreen from '../Region/RegionViewScreen';
import ServiceViewScreen from '../Service/ServiceViewScreen';
import UserViewScreen from '../User/UserViewScreen';
import PostViewScreen from '../Post/PostViewScreen';
import SystemMessage from '../SystemMessage/SystemMessageViewScreen';
const { Content } = Layout;

export default function AppContent(props) {
  return (
    <Content id="mainLayoutContent">
      <div>
        <Switch>
          <Route path="/family" component={FamilyViewScreen} />
          <Route path="/region" component={RegionViewScreen} />
          <Route path="/service" component={ServiceViewScreen} />
          <Route path="/user" component={UserViewScreen} />
          <Route path="/post" component={PostViewScreen} />
          <Route path="/system-message" component={SystemMessage} />
          <Route component={HomeScreen} />
        </Switch>
      </div>
    </Content>
  );
};
