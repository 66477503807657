import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import { Dropdown, Menu, Button, Modal, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolPermission from '../../tools/ToolPermission'

export default function FamilyViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'super-admin', 'get')
        return canEdit ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {

                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'name',
      title: I18n.t('名稱'),
      render: (value, record) => {
        return value && value[userData.locale]
      },
    },
    {
      key: 'remark',
      title: I18n.t('備註'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const regionRes = await ToolRequest.request('GET', '/region', {
      ...body,
    })
    return regionRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('地區列表')}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull/>
};
