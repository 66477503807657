import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment';

import { Modal, Form, Input, Button, Spin, Select, message, Switch,Space,InputNumber,DatePicker } from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function UserServiceAddModal (props) {
  const userData = useSelector(state => state.user.value)
  const {visible, onOk, onCancel,userId} = props
  const user = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [serviceOptions, setServiceOptions] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const serviceRes = await ToolRequest.request('GET', `/service`)
        console.dir(serviceRes)
        const options = serviceRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setServiceOptions(options)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      const data = {
        userId,
        serviceId: values.serviceId
      }
      await ToolRequest.request('POST', `/user-service`, data)
      message.success(I18n.t('addSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        console.dir(err.response.data)
        switch (err.response.data) {
          case 'USER_SERVICE_ALREADY_EXISTS': {
            return message.error(I18n.t('用戶已綁定此服務，請勿重覆綁定'))
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('添加用戶服務')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              user

            }
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('服務')}
              name="serviceId"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('服務')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {serviceOptions}
              </Select>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};