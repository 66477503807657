import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Divider,
  InputNumber,
  DatePicker,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function FamilyAddModal (props) {
  const userData = useSelector(state => state.user.value)
  const {visible, onOk, onCancel} = props
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [regionOptions, setRegionOptions] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const regionRes = await ToolRequest.request('GET', `/region`)
        const regionOptions = regionRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setRegionOptions(regionOptions)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    console.dir(values)
    try {
      let members = [
        {
          firstNameEn: values.firstNameEn,
          lastNameEn: values.lastNameEn,
          idNumber: values.idNumber,
          phone: values.phone,
          isMain:true
        }
      ]
      const data = {
        isEnabled: values.isEnabled,
        remark: values.remark,
        economicStatus: values.economicStatus,
        monthlyIncome: values.monthlyIncome,
        address: values.address,
        regionId: values.regionId,
        smsSentAt: values.smsSentAt,
        locale: values.locale,
        applicantPhone: values.phone,
        members
      }
      let flag = true
      if(!data.smsSentAt){
        flag = await new Promise((resolve,reject)=>{
          Modal.confirm({
            title: I18n.t('短訊發送時間尚未選擇，確定要留空嗎？留空將不會發送短訊'),
            okType: 'danger',
            onOk: () => {
              resolve(true)
            },
            onCancel: () => {
              resolve(false)
            },
          });
        })
      }
      if(flag){
        setLoading(true)
        const familyRes = await ToolRequest.request('POST', `/family`, data)
        message.success(I18n.t('addSuccessMessage'))
        onOk(familyRes.data.id)
        setLoading(false)
      }
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          case 'INVALID_MEMBER_PHONE': {
            return message.error(I18n.t('請填寫申請人電話'))
          }
          case 'DUPLICATE_USER_PHONE': {
            return message.error(I18n.t('申請人電話重覆'))
          }
          default:
            return message.error(I18n.t(err.response.data))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('添加家庭')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={{
              locale:'zh-tw'
            }}
            onFinish={onFinish}
          >
            <Divider>家庭資料</Divider>
            <Form.Item
              label="啟用"
              name="isEnabled"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked={true}/>
            </Form.Item>
            <Form.Item
              label={I18n.t('地區')}
              name="regionId"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('地區')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {regionOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('經濟狀況')}
              name="economicStatus"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredSelectAny', {
                    name: I18n.t('經濟狀況'),
                  }),
                },
              ]}
            >
              <Select placeholder={I18n.t('經濟狀況')}>
                <Option value="有收入">
                  {I18n.t('有收入')}
                </Option>
                <Option value="領取綜援金">
                  {I18n.t('領取綜援金')}
                </Option>
                <Option value="其它">
                  {I18n.t('其它')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('每月收入')}
              name="monthlyIncome"
            >
              <InputNumber
                placeholder={I18n.t('每月收入')}
                min={1}
                step={0.01} style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('短訊發送時間')}
              name="smsSentAt"
            >
              <DatePicker
                showTime
                placeholder={I18n.t('短訊發送時間')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('語言')}
              name="locale"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select placeholder={I18n.t('語言')}>
                <Option value="zh-tw">
                  {I18n.t('繁體中文')}
                </Option>
                <Option value="zh-cn">
                  {I18n.t('簡體中文')}
                </Option>
                <Option value="en">
                  {I18n.t('英文')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('地址')}
              name="address"
            >
            <TextArea
              placeholder={I18n.t('地址')}
            />
            </Form.Item>
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Divider>申請人資料</Divider>
            <Form.Item
              label={I18n.t('英文名字')}
              name="firstNameEn"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('英文名字'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('英文名字')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('英文姓氏')}
              name="lastNameEn"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('英文姓氏'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('英文姓氏')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('身份證號')}
              name="idNumber"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('身份證號'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('身份證號')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('電話號碼')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('電話號碼'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('電話號碼')}
              />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};