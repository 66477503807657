import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Form, Switch, Select, InputNumber,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import UserServiceAddModal from './UserServiceAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function UserServiceViewModal (props) {
  const userData = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [userServiceAddModal, setUserServiceAddModal] = useState(null)
  const {userId,visible, onOk, onCancel} = props
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [services, setServices] = useState([])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/user-service/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'userId',
      title: I18n.t('用戶'),
      render: (value, record) => {
        if(user.id === value){
          return user.fullName
        }
      },
    },
    {
      key: 'serviceId',
      title: I18n.t('服務'),
      render: (value, record) => {
        const service = services.find(item=>item.id === value)
        return service && service.name[userData.locale]
      },
    },
    {
      template: 'datetime',
      key: 'createdAt',
      sorter:true,
      title: I18n.t('createdAt'),
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const userRes = await ToolRequest.request('GET', `/user/${userId}`)
      setUser(userRes.data)
      const serviceRes = await ToolRequest.request('GET', `/service`)
      setServices(serviceRes.data)
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const userServiceRes = await ToolRequest.request('GET', `/user-service`, {
      userId,
      ...body,
    })
    return userServiceRes
  }

  return (
    <Modal
      title={I18n.t('')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={I18n.t('用戶服務列表')}
            extra={[
              (
                <Button
                  key={Math.random().toString()}
                  type="primary"
                  icon={<PlusOutlined/>}
                  onClick={() => setUserServiceAddModal({
                    userId,
                    visible: true,
                    key: Math.random().toString(),
                  })}
                >
                  {I18n.t('add')}
                </Button>
              ),
            ]}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {userServiceAddModal && <UserServiceAddModal
              key={userServiceAddModal.key}
              visible={userServiceAddModal.visible}
              userId={userServiceAddModal.userId}
              onOk={() => {
                setUserServiceAddModal({
                  ...userServiceAddModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setUserServiceAddModal({
                  ...userServiceAddModal,
                  visible: false,
                })
              }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
