import React, { useState, useEffect } from 'react'
import { I18n } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  message,
  DatePicker, Select,
} from 'antd'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import Import from '../../components/Import'
import moment from 'moment'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function FamilyServiceBatchAddModal (props) {
  const {visible, onOk, onCancel,serviceId } = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [families, setFamilies] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const familyRes = await ToolRequest.request('GET', '/family')
        setFamilies(familyRes.data)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    console.dir(values)
    try {
      const familyArr = values.families ? values.families.split('\n') : []
      if(!familyArr.length){
        await Promise.reject(new Error(`請輸入家庭編號或家庭電話`))
      }
      let familyIds = []
      for (let inputFamily of familyArr) {
        const number = inputFamily.trim()
        const family = families.find(item=>item.familyNumber === number || item.applicantPhone === number)
        if(!family){
          await Promise.reject(new Error(`${number} 家庭不存在，請修復后重新提交`))
        }
        familyIds.push(family.id)
      }
      const data = {
        familyIds,
        serviceId,
        beginAt: values.beginAt,
        endAt: values.endAt,
        memberServiceOption: values.memberServiceOption,
      }
      console.dir(data)
      const result =  await ToolRequest.request('POST', `/backend/family-service/batch-add`, data)
      if(result.familyNumbers.length){
        await Promise.reject(new Error(`${result.familyNumbers.join('; ')} 家庭已存在進行中或等待中的任務，已跳過添加`))
      }
      message.success('批量添加家庭服務成功')
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(err.response.data)
        }
      }
      const errorMessage = err.message || message.error(I18n.t('errorMessageUnknownError'))
      message.error(errorMessage)
    }
  }

  return (
    <Modal
      title={I18n.t('批量添加家庭服務')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={{
              beginAt:moment()
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="家庭編號或家庭電話"
              rules={[
                {
                  required: true
                },
              ]}
              name="families"
            >
              <TextArea rows={6} placeholder="100001&#10;100002&#10;3345678"/>
            </Form.Item>
            <Form.Item
              label={I18n.t('開始時間')}
              name="beginAt"
              required={true}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(!value){
                      return Promise.reject(new Error(I18n.t('請輸入開始時間')));
                    }
                    const endAt = getFieldValue('endAt')
                    if(moment(value).isAfter(endAt)){
                      return Promise.reject(new Error(I18n.t('開始時間不能大於結束時間')))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder={I18n.t('開始時間')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('結束時間')}
              name="endAt"
              required={true}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(!value){
                      return Promise.reject(new Error(I18n.t('請輸入結束時間')));
                    }
                    const beginAt = getFieldValue('beginAt')
                    if(moment(value).isBefore(beginAt)){
                      return Promise.reject(new Error(I18n.t('結束時間不能小於開始時間')))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder={I18n.t('結束時間')}
              />
            </Form.Item>
            <Form.Item
                label={I18n.t('成員服務')}
                name="memberServiceOption"
                validateStatus="error"
                help="是否在子服務中啟用該家庭的所有成員，會影響某些子服務的配額，請謹慎開啟。如之前曾加入過此服務，可選擇 ‘保持不變’，使用舊設置。要單獨設置成員子服務，可前往 家庭列表->家庭成員->成員服務列表設置"
                rules={[
                  {
                    required: true
                  },
                ]}
            >
              <Select placeholder={I18n.t('成員服務')}>
                <Option value="keep">
                  {I18n.t('保持不變')}
                </Option>
                <Option value="true">
                  {I18n.t('開啟')}
                </Option>
                <Option value="false">
                  {I18n.t('關閉')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};