import React, { useState, useEffect } from 'react'

import {
  Image,
  Modal,
} from 'antd'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

export default function QrCodeViewModal (props) {
  const [loaded, setLoaded] = useState(false)
  const {familyId,familyNumber,visible, onCancel} = props
  const [qrCodeUrl, setQrCodeUrl] = useState(null)

  useEffect(() => {
    (async () => {
      const res = await ToolRequest.request('GET', `/qr-code/member`,{familyId})
      setQrCodeUrl(res.data.url)
      setLoaded(true)
    })()
  }, [])

  return (
    <Modal
      title={familyNumber}
      open={visible}
      onCancel={onCancel}
      width={250}
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
          <Image width={200} src={qrCodeUrl}/>
      ) : <SpinFull/>}
    </Modal>
  )
};
