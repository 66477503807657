import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Upload,
  Space,
  InputNumber,
  DatePicker, Switch,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { I18n } from 'react-redux-i18n'

export default function Import (props) {
  const {
    onOk,
    accept,
    action,
    listType,
    maxCount,
    fileList,
    setFileList,
    showUploadList = true,
    data = {},
    ...rest
  } = props
  const [loading, setLoading] = useState(false)
  const handleChange = (info) => {
    setLoading(true)
    console.dir(info)
    if (info.file.error) {
      return message.error(info.file.response)
    }
    if (info.file.status === 'done') {
      if (info.file.response.message) {
        message.success(info.file.response.message)
      }
      if (onOk) {
        return onOk()
      }
    }
    setFileList(info.fileList)
    setLoading(false)
  }

  return (
    <Upload
      accept={accept}
      action={action}
      listType={listType}
      maxCount={maxCount}
      fileList={fileList}
      showUploadList={showUploadList}
      withCredentials={true}
      onChange={handleChange}
      data={data}
    >
      <Button icon={<UploadOutlined/>}>{rest.buttonText || '上傳文件'}</Button>
    </Upload>
  )
};
