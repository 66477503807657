import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import moment from 'moment';

import {Button, Modal, message, Tooltip, PageHeader,Table, Tag,Spin} from 'antd'
import {
  SyncOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
  UploadOutlined,
  DownloadOutlined, PlusOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import SuperTable from "../../components/SuperTable";
import * as ToolPermission from "../../tools/ToolPermission";

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

export default function UserSystemMessageAddModal (props) {
  const userData = useSelector(state => state.user.value)
  const userPermissionsData = useSelector(state => state.userPermissions.value);
  const {visible, onOk, onCancel,systemMessageId} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [regions, setRegions] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [members, setMembers] = useState([])
  const [services, setServices] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [serviceOptions, setServiceOptions] = useState([])
  const [systemMessage, setSystemMessage] = useState(null)

  useEffect(() => {
    (async () => {
      const [
        regionRes,
        memberRes,
        serviceRes,
          systemMessageRes
      ] = await Promise.all([
        ToolRequest.request('GET', '/region'),
        ToolRequest.request('GET', '/member',{limit:5000}),
        ToolRequest.request('GET', '/service',{limit:5000}),
        ToolRequest.request('GET', `/system-message/${systemMessageId}`),
      ])
      setSystemMessage(systemMessageRes.data)
      setRegions(regionRes.data)
      const regionOptions = regionRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setRegionOptions(regionOptions)
      setMembers(memberRes.data)
      const serviceOptions = serviceRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setServices(serviceRes.data)
      setServiceOptions(serviceOptions)
      setLoaded(true)
    })()
  }, [tableKey])

  const columns = [
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'familyNumber',
      title: I18n.t('familyNumber'),
      sorter:true,
    },
    {
      key: 'regionId',
      title: I18n.t('地區'),
      render: (value, record) => {
        const region = regions.find(item => item.id === value)
        if (region) {
          return region.name[userData.locale]
        }
      },
    },
    {
      key: 'familyServices',
      title: I18n.t('服務'),
      render: (value, record) => {
        const columns = [
          {
            title: '服务',
            dataIndex: 'service',
            key: 'service',
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (value, record) => {
              const beginAt = record.beginAt ? moment(record.beginAt).format('YYYY-MM-DD HH:mm:ss') : ''
              const endAt = record.endAt ? moment(record.endAt).format('YYYY-MM-DD HH:mm:ss') : ''
              return {
                ONGOING: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag></Tooltip>,
                WAITING: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag></Tooltip>,
                ENDED: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag></Tooltip>,
                TERMINATED: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag></Tooltip>,
              }[value];
            },
          },
        ]
        const data = value.map(item=>{
          const service = services.find(service=>service.id === item.service_id)
          return {
            key:item.id,
            service:service ? service.name[userData.locale] : '',
            status:item.status,
            beginAt:item.begin_at ? moment(item.begin_at).format('YYYY-MM-DD HH:mm:ss') : '',
            endAt:item.end_at ? moment(item.end_at).format('YYYY-MM-DD HH:mm:ss') : '',
          }
        })
        return <Table columns={columns} dataSource={data} pagination={false} showHeader={false}/>
      },
    },
    {
      key: 'mainMemberId',
      title: I18n.t('申請人'),
      render: (value, record) => {
        const member = members.find(item => item.id === value)
        return member && `${member.firstNameEn} ${member.lastNameEn}`
      },
    },
    {
      key: 'applicantPhone',
      title: I18n.t('聯繫電話'),
      sorter:true,
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      title: I18n.t('啟用'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'regionId',
      title: I18n.t('地區'),
      options: regionOptions
    },
    {
      template: 'select',
      key: 'serviceId',
      title: I18n.t('服務'),
      options: serviceOptions
    },
    {
      template: 'select',
      key: 'serviceStatus',
      title: '服務狀態',
      options: [
        {
          label: <Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag>,
          value: 'ONGOING'
        },
        {
          label: <Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag>,
          value: 'WAITING'
        },
        {
          label: <Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag>,
          value: 'ENDED'
        },
        {
          label: <Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag>,
          value: 'TERMINATED'
        },
      ]
    },
    {
      template: 'select',
      key: 'isEnabled',
      title: I18n.t('啟用狀態'),
      defaultValue:true,
      options: [
        {
          label:'啟用',
          value:true,
        },
        {
          label:'停用',
          value:false,
        },
      ]
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      regionId: values.regionId,
      serviceId: values.serviceId,
      serviceStatus: values.serviceStatus,
      isEnabled: values.isEnabled,
    }
  }

  const loadDataSource = async (body) => {
    const familyRes = await ToolRequest.request('GET', '/family', {
      ...body,
    })
    return familyRes
  }

  const onSelectFamilyOk = ()=>{
    Modal.confirm({
      title: `已選擇 ${selectedRowKeys.length} 個家庭，保存請按確定，返回選擇請按取消`,
      okType: 'danger',
      onOk: () => {
        (async () => {
          try {
            setLoading(true)
            await Promise.all(selectedRowKeys.map(familyId=>{
              const params = {
                systemMessageId,
                familyId
              }
              return ToolRequest.request('POST', `/user-system-message`, params)
            }))
            message.success(I18n.t('addSuccessMessage'))
            onOk()
            setLoading(false)
          } catch (err) {
            console.dir(err)
            setLoading(false)
            if (err?.response?.data) {
              console.dir(err.response.data)
              switch (err.response.data) {
                default:
                  return message.error(I18n.t('errorMessageUnknownError'))
              }
            }else {
              if(err && err.message){
                message.error(err.message)
              }else {
                message.error(I18n.t('errorMessageUnknownError'))
              }
            }
          }
        })()
      },
    })
  }

  return (
    <Modal
      title={`添加用戶系統消息 (${systemMessage ? systemMessage.title[userData.locale] : ''})`}
      onCancel={onCancel}
      open={visible}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
          <Spin spinning={loading}>
            <PageHeader
              title={I18n.t('家庭列表')}
              extra={[
              ToolPermission.hasPermission(userPermissionsData, 'family', 'post') &&(
                  <Button
                      type="primary"
                      shape="round"
                      icon={<EditOutlined />}
                      onClick={()=>{
                        if(selectedRowKeys.length){
                          onSelectFamilyOk()
                        }else {
                          message.error(I18n.t('請至少選擇一個家庭'))
                        }
                      }}
                  >
                    批量添加用戶系統消息
                  </Button>
              ),
            ]}>
              <SuperTable
                  showSearchInput
                  searchQueryParams={searchQueryParams}
                  tableKey={tableKey}
                  onTableKeyChange={key => setTableKey(key)}
                  loadDataSource={loadDataSource}
                  columns={columns}
                  searchColumns={searchColumns}
                  selectable="checkbox"
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
              />
            </PageHeader>
          </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};