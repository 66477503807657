import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Badge, Switch, Select, InputNumber,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import UserSystemMessageAddModal from './UserSystemMessageAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function UserSystemMessageViewModal (props) {
  const userData = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [userSystemMessageAddModal, setUserSystemMessageAddModal] = useState(null)
  const {systemMessageId,visible, onOk, onCancel} = props
  const [loading, setLoading] = useState(false)
  const [systemMessage, setSystemMessage] = useState(null)
  const [families, setFamilies] = useState([])
  const [users, setUsers] = useState([])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/user-system-message/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'systemMessageId',
      title: I18n.t('系統消息'),
      render: (value, record) => {
        if(value){
          return value === systemMessage.id && systemMessage.title[userData.locale]
        }
      },
    },
    {
      key: 'userId',
      title: I18n.t('家庭'),
      render: (value, record) => {
        if(value){
          const user = users.find(item=>item.id === value)
          if(user){
            const family = families.find(item=>item.id === user.familyId)
            return family && family.familyNumber
          }
        }
      },
    },
    {
      template: 'isEnabled',
      key: 'isRead',
      title: I18n.t('已讀'),
      sorter:true,
      render: (value, record) => {
        if(value){
          return <Badge status="success"/>
        }else {
          return <Badge status="default"/>
        }
      },
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const familyRes = await ToolRequest.request('GET', `/family`)
      setFamilies(familyRes.data)
      const userRes = await ToolRequest.request('GET', `/user`,{type:'FAMILY_USER'})
      setUsers(userRes.data)
      const systemMessageRes = await ToolRequest.request('GET', `/system-message/${systemMessageId}`)
      setSystemMessage(systemMessageRes.data)
      setLoaded(true)
    })()
  }, [tableKey])

  const loadDataSource = async (body) => {
    const userSystemMessageRes = await ToolRequest.request('GET', `/user-system-message`, {
      systemMessageId,
      ...body,
    })
    return userSystemMessageRes
  }

  return (
    <Modal
      title={I18n.t('')}
      open={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={I18n.t('用戶系統消息列表')}
            extra={[
              (
                <Button
                  key={Math.random().toString()}
                  type="primary"
                  icon={<PlusOutlined/>}
                  onClick={() => setUserSystemMessageAddModal({
                    systemMessageId,
                    visible: true,
                    key: Math.random().toString(),
                  })}
                >
                  {I18n.t('add')}
                </Button>
              ),
            ]}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {userSystemMessageAddModal && <UserSystemMessageAddModal
              key={userSystemMessageAddModal.key}
              visible={userSystemMessageAddModal.visible}
              systemMessageId={userSystemMessageAddModal.systemMessageId}
              onOk={() => {
                setUserSystemMessageAddModal({
                  ...userSystemMessageAddModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setUserSystemMessageAddModal({
                  ...userSystemMessageAddModal,
                  visible: false,
                })
              }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
