export default {
  "appHeaderChangePasswordModalConfirmPassword": "確認密碼",
  "appHeaderChangePasswordModalConfirmPasswordErrorMessage": "您輸入的兩個新密碼不一致！",
  "appHeaderChangePasswordModalIncorrectPassword": "舊密碼不正確，請重新輸入",
  "appHeaderChangePasswordModalNewPassword": "新密碼",
  "appHeaderChangePasswordModalOldPassword": "您的密碼",
  "appHeaderChangePasswordModalSubmit": "提交",
  "appHeaderChangePasswordModalTitle": "修改密碼",
  "appHeaderGreetings": "您好!",
  "appHeaderCurrentUser": "已登入",
  "userName": "用戶名",
  "family": "家庭",
  "familyNumber": "家庭號",
  "search": "搜索",
  "add": "添加",
  "title": "標題",
  "myAccount": "我的帳號",
  "logout": "登出",
  "setting": "設定",
  "changePassword": "修改密碼",
  "errorMessageRequiredInputAny": "請輸入%{name}",
  "errorMessageRequiredSelectAny": "請選擇%{name}",
  "errorMessageUnknownError": "未知錯誤, 請稍後重試",
  "addSuccessMessage": "添加成功",
  "editSuccessMessage": "修改成功",
  "removeSuccessMessage": "刪除成功",
  "createdAt": "創建時間",
  "removeConfirmMessage": "您確定要刪除此數據嗎?",
  "removeErrorMessage": "刪除失敗",
  "tablePaginationTotal": "總共 %{total} 個記錄",
  "tableSearchPlaceholder": "搜索...",
}