import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Badge, Switch, Select, InputNumber,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import MemberServiceEditModal from './MemberServiceEditModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function MemberServiceViewModal (props) {
  const {memberId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [memberServiceEditModal, setMemberServiceEditModal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [member, setMember] = useState(false)
  const [services, setServices] = useState([])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
              menu={{
                items:[
                  {
                    key: 'edit',
                    label: '編輯'
                  },
                ],
                onClick: ({key}) => {
                  switch (key) {
                    case 'edit': {
                      setMemberServiceEditModal({
                        visible: true,
                        memberServiceId: record.id,
                        key: Math.random().toString(),
                      })
                      break
                    }
                  }
                }
              }}
          >
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'memberId',
      title: I18n.t('中文姓名'),
      render: (value, record) => {
        return `${member.chineseFullName}`
      },
    },
    {
      key: 'memberId',
      title: I18n.t('英文姓名'),
      render: (value, record) => {
        return `${member.firstNameEn} ${member.lastNameEn}`
      },
    },
    {
      key: 'serviceId',
      title: I18n.t('服務'),
      sorter:true,
      render: (value, record) => {
        const service = services.find(item=>item.id === value)
        return service ? service.name['zh-tw'] : null
      },
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      title: I18n.t('啟用'),
      render: (value, record) => {
        if(value){
          return <Badge status="success"/>
        }else {
          return <Badge status="default"/>
        }
      },
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const memberRes = await ToolRequest.request('GET', `/member/${memberId}`)
      setMember(memberRes.data)
      const serviceRes = await ToolRequest.request('GET', `/service`)
      setServices(serviceRes.data)
      setLoaded(true)
    })()
  }, [tableKey])

  const loadDataSource = async (body) => {
    const memberServiceRes = await ToolRequest.request('GET', `/member-service`, {
      memberId,
      ...body,
    })
    console.dir(memberServiceRes)
    return memberServiceRes
  }

  return (
    <Modal
      title={I18n.t('')}
      open={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={I18n.t('成員服務列表')}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {memberServiceEditModal && <MemberServiceEditModal
              key={memberServiceEditModal.key}
              visible={memberServiceEditModal.visible}
              memberServiceId={memberServiceEditModal.memberServiceId}
              onOk={() => {
                setMemberServiceEditModal({
                  ...memberServiceEditModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setMemberServiceEditModal({
                  ...memberServiceEditModal,
                  visible: false,
                })
              }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
