import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Spin, ConfigProvider } from 'antd';
import { setLocale } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';

import LoginScreen from '../Login/LoginScreen';
import AppScreen from '../App/AppScreen';
import { updateUser } from '../../reducers/userSlice';
import { updateMetadata } from '../../reducers/metadataSlice';
import { updateUserPermissions } from '../../reducers/userPermissionsSlice';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolUser from '../../tools/ToolUser';

import '../../less/SplashScreen.less';

import enUS from 'antd/es/locale/en_US';
import zhTW from 'antd/es/locale/zh_TW';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

export default function SplashScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);

  let locale = zhTW;
  if (userData) {
    switch (userData.locale) {
      case 'zh-tw':
        locale = zhTW;
        break;
      case 'zh-cn':
        locale = zhCN;
        break;
      case 'en':
        locale = enUS;
        break;
    }
  }

  useEffect(() => {
    const f = async ()=>{
      const metadata = await ToolRequest.request('GET', '/metadata')
      console.dir(metadata)
      dispatch(updateMetadata(metadata))
      const userRes = await ToolRequest.request('GET', '/user/me')
      const user = userRes.data
      ToolUser.updateLocale(user.locale);
      dispatch(setLocale(user.locale));
      dispatch(updateUser(user));
      const userPermissionsRes = await ToolRequest.request('GET', '/user-permission')
      console.dir(userPermissionsRes.data)
      dispatch(updateUserPermissions(userPermissionsRes.data));
    }
    f().then(()=>{
      setLoaded(true)
    }).catch(err=>{
      setLoaded(true)
      console.dir(err)
      history.replace(`/login?next=${encodeURIComponent(history.location.pathname)}`);
    })
  }, []);

  return (
    <ConfigProvider locale={locale}>
      {loaded ? (
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route path="/" component={AppScreen} />
        </Switch>
      ) : (
        <div id="splashContainer">
          <Spin />
        </div>
      )}
    </ConfigProvider>
  );
};
